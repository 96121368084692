/* You can add global styles to this file, and also import other style files */
@tailwind base;
@tailwind components;
@tailwind utilities;

input.input {
    @apply input-ghost input-bordered border-primary-content;
}
body {
    background-color: transparent;
    padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
    color-scheme: only light;
}

@keyframes enter {
    from {
        transform: translate3d(100%, 0, 0);
        visibility: visible;
    }
    
    to {
        transform: translate3d(0, 0, 0);
    }
}
  
@keyframes exit {
    from {
        transform: translate3d(0, 0, 0);
    }
    
    to {
        visibility: hidden;
        transform: translate3d(-100%, 0, 0);
    }
}
 
::view-transition-old(root) {
    // animation: exit 1s ease 0s 1 normal forwards;
    animation-name: exit;
    animation-timing-function: ease-in-out;
    animation-duration: 1s;
    animation-delay: 100ms;
}
  
::view-transition-new(root) {
    // animation: enter 1s ease 0s 1 normal forwards;
    animation-name: enter;
    animation-timing-function: ease-in-out;
    animation-duration: 1s;
    animation-delay: 100ms;
}

:root {
    color-scheme: only light;
}